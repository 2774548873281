<template>
  <div>
    <VueForm ref="form">
      <VueText sizeLevel="11" weightLevel="3" class="header" color="grey-40"> Giriş Yap</VueText>
      <VueText sizeLevel="6" weightLevel="3" class="small-header"
        >Müşteri Kodu ile Giriş Yap</VueText
      >
      <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40"
        >Müşteri Kodunuz</VueText
      >
      <VueInput
        name="customerNumber"
        id="customerNumber"
        type="text"
        mask="XXXXXXXXXXX"
        label="Müşteri Kodu"
        placeholder="---------"
        validatorName="Müşteri Numarası"
        v-model="formModel.customerNumber"
        :messageOnTop="true"
        :inputLarge="true"
        :validationRules="
          `required|max:11${
            partnerCodes.some(i =>
              i.toLowerCase().includes(formModel.customerNumber.slice(0, i.length).toLowerCase()),
            )
              ? ''
              : '|min:9'
          }`
        "
        nospace
      />
      <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40">Şifreniz</VueText>
      <VueInput
        :type="passwordType"
        name="password"
        id="password"
        validatorName="Şifre"
        v-model="formModel.password"
        :messageOnTop="true"
        :inputLarge="true"
        validationRules="required|max:20|min:1"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          :iconName="getIcon.eye.name"
          :iconSize="{ width: getIcon.eye.width, height: getIcon.eye.height }"
          :iconColor="this.passwordType === 'password' ? '' : '#79838E'"
          @click.prevent="togglePassword"
        ></VueButton>
      </VueInput>
      <VueGridRow class="grid-row" hAlignment="between">
        <VueGridCol :size="6" alignment="center">
          <VueButton
            class="remember-me"
            :outlined="true"
            v-show="rememberMe"
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            @click.prevent="toggleRemember"
            as="a"
            :iconName="getIcon.tick.name"
            :iconSize="{ width: getIcon.tick.width, height: getIcon.tick.height }"
            >Beni Hatırla</VueButton
          >
          <VueButton
            class="remember-me"
            :outlined="true"
            v-show="!rememberMe"
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            @click.prevent="toggleRemember"
            as="a"
            :iconName="getIcon.circle.name"
            :iconSize="{ width: getIcon.circle.width, height: getIcon.circle.height }"
            >Beni Hatırla</VueButton
          >
        </VueGridCol>
        <VueGridCol :size="6" alignment="center">
          <VueButton
            class="forgot-password"
            :outlined="true"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :ghost="true"
            as="router-link"
            :target="routes.login.FirstLogin.path"
            :iconName="getIcon.questionMark.name"
            :iconSize="{ width: getIcon.questionMark.width, height: getIcon.questionMark.height }"
            >Şifremi Unuttum</VueButton
          >
        </VueGridCol>
      </VueGridRow>
      <div slot="footer" class="button-wrapper">
        <BrandButton
          :disabled="isDisabled"
          class="submit-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          @click.prevent="handleLoginRequest"
          >GİRİŞ</BrandButton
        >
        <BrandButton
          class="submit-button"
          :size="sizes.xxLarge"
          :outlined="true"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          @click.prevent="redirectToLogin"
          >GERİ DÖN</BrandButton
        >
      </div>
    </VueForm>
    <BrandAuthFormFooter></BrandAuthFormFooter>
  </div>
</template>

<script>
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueGridRow from '@/components/shared/VueGridRow/VueGridRow.vue';
import VueGridCol from '@/components/shared/VueGridCol/VueGridCol.vue';
import StorageHelper from '@/utils/storageHelper';
import LoginMixin from '@/mixins/login.js';
import BrandAuthFormFooter from '@/components/brand/BrandAuthFormFooter/BrandAuthFormFooter.vue';
import Account from '@/services/Api/account.js';

export default {
  name: 'CustomerNumber',
  components: {
    BrandAuthFormFooter,
    VueInput,
    VueButton,
    VueForm,
    BrandButton,
    VueGridRow,
    VueGridCol,
    VueText,
  },
  mixins: [LoginMixin],
  data: () => {
    return {
      isDisabled: true,
      formModel: {
        customerNumber: '',
        password: '',
      },
      submitted: false,
      passwordType: 'password',
      rememberMe: false,
      partnerCodes: [],
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return {
        eye: ICON_VARIABLES.eye,
        circle: ICON_VARIABLES.circle,
        questionMark: ICON_VARIABLES.questionMark,
        tick: ICON_VARIABLES.rememberMe,
      };
    },
    routes() {
      return {
        auth: RoutesAthentication,
        login: RoutesLogin,
      };
    },
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        if (this.formModel.customerNumber !== '' && this.formModel.password !== '') {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push(this.routes.login.SelectLoginType.path);
    },
    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
    toggleRemember() {
      this.rememberMe = !this.rememberMe;
    },
  },
  created() {
    const storageNo = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_CURRENT_USER_CN_KEY,
    }).get(null, true);

    if (storageNo) {
      if (storageNo.timestamp - new Date().getTime() > 60 * 60 * 100 * 24) {
        this.formModel.customerNumber = '';
      } else {
        this.formModel.customerNumber = storageNo.data;
        this.rememberMe = storageNo.data ? true : false;
      }
    }
    Account.getPartnerCodes()
      .then(res => {
        if (res.data && res.data.Data && res.data.Data.partnerCodes) {
          this.partnerCodes = res.data.Data.partnerCodes;
        }
      })
      .catch(err => console.log(err.message));
  },
};
</script>

<style lang="scss">
.header {
  padding-top: palette-space-level('60');
}

.small-header {
  padding-top: palette-space-level('10');
  padding-bottom: palette-space-level('10');
}

.form-text {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

.grid-row {
  padding-top: palette-space-level('25');

  .forgot-password {
    margin-left: palette-space-level('80');
  }
}

.button-wrapper {
  padding: palette-space-level('40') 0 palette-space-level('20');
  .submit-button {
    border-radius: palette-radius-level('2');
    font-weight: palette-font-weight-level('3');
    &:first-child {
      margin-bottom: palette-space-level('10');
    }
    &:last-child {
      span {
        color: palette-color('red-10');
      }
    }
  }
}

.forgot-password,
.remember-me {
  font-size: palette-font-size-level('4');
}
</style>
